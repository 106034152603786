import Vue from 'vue';
import Vuex from 'vuex';
import { ICourseModalState } from '@/types/courses/CourseModal';
import { IMapState } from '@/store/modules/map';
import { ILanguageState } from './modules/language';
import { IAppState } from './modules/app';
import { IUserState } from './modules/user';
import { IProfessionState } from './modules/profession';
import { INotebookState } from './modules/notebook';
import { IWorkersState } from './modules/workers';
import { IClustersState } from './modules/clusters';
import { ICompanyState } from './modules/company';
import { IThemesState } from './modules/themes';
import { ICourseState } from './modules/courses/courses';
import { IJobDevelopmentModalState } from './modules/jobDevelopmentModal';
import { IGroupSidebarState } from './modules/groupSidebar';

Vue.use(Vuex);
export interface IRootState {
  app: IAppState
  language: ILanguageState
  user: IUserState
  professions: IProfessionState
  notebook: INotebookState
  workers: IWorkersState
  map: IMapState
  clusters: IClustersState
  company: ICompanyState
  themes: IThemesState
  coursesModule: ICourseState
  courseModalModule: ICourseModalState
  groupSidebar: IGroupSidebarState
  jobDevelopmentModal: IJobDevelopmentModalState
}

export default new Vuex.Store<IRootState>({});
