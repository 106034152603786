<template>
  <div>
    <header v-if="isIframe">
      <template v-if="groupTemplate">
        <component :is="`header-information-navigation-${sid}`" />
      </template>
      <information-navigation v-else />
      <pages-navigation />
    </header>
    <main>
      <InfoBanner></InfoBanner>
      <b-container fluid class="pt-0 pb-5 p-0" id="content">
        <b-container>
          <b-row>
            <b-col cols="12" :xl="isIframe ? 10 : 12">
              <router-view />
            </b-col>
            <b-col v-if="isIframe" cols="12" xl="2" class="py-2 py-md-4">
              <right-sidebar :class="rightSidebarClass" />
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </main>
    <template v-if="isIframe">
      <template v-if="groupTemplate">
        <component :is="`footer-component-${sid}`" />
      </template>
      <footer-component v-else />
      <terms-modal />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import InformationNavigation from '@/components/Header/InformationNavigation.vue';
import HeaderInformationNavigationJig from '@/components/Themes/HeaderInformationNavigationJig.vue';
import HeaderInformationNavigationTalentpool from '@/components/Themes/HeaderInformationNavigationTalentpool.vue';
import HeaderInformationNavigationJobtalent from '@/components/Themes/HeaderInformationNavigationJobtalent.vue';
import HeaderInformationNavigationEdunet from '@/components/Themes/HeaderInformationNavigationEdunet.vue';
import HeaderInformationNavigationEdunetke from '@/components/Themes/HeaderInformationNavigationEdunetke.vue';
import HeaderInformationNavigationEdunetasia from '@/components/Themes/HeaderInformationNavigationEdunetasia.vue';
import HeaderInformationNavigationEdunetin from '@/components/Themes/HeaderInformationNavigationEdunetin.vue';
import HeaderInformationNavigationEdunetma from '@/components/Themes/HeaderInformationNavigationEdunetma.vue';
import HeaderInformationNavigationT4a from '@/components/Themes/HeaderInformationNavigationT4a.vue';
import HeaderInformationNavigationNcph from '@/components/Themes/HeaderInformationNavigationNcph.vue';
import HeaderInformationNavigationWconat from '@/components/Themes/HeaderInformationNavigationWconat.vue';
import HeaderInformationNavigationKh from '@/components/Themes/HeaderInformationNavigationKh.vue';
import HeaderInformationNavigationRhh from '@/components/Themes/HeaderInformationNavigationRhh.vue';
import HeaderInformationNavigationHe from '@/components/Themes/HeaderInformationNavigationHe.vue';
import PagesNavigation from '@/components/Header/PagesNavigation.vue';
import RightSidebar from '@/components/RightSidebar.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import FooterComponentJig from '@/components/Themes/FooterComponentJig.vue';
import FooterComponentJobtalent from '@/components/Themes/FooterComponentJobtalent.vue';
import FooterComponentTalentpool from '@/components/Themes/FooterComponentTalentpool.vue';
import FooterComponentKh from '@/components/Themes/FooterComponentKh.vue';
import FooterComponentRhh from '@/components/Themes/FooterComponentRhh.vue';
import FooterComponentHe from '@/components/Themes/FooterComponentHe.vue';
import FooterComponentEdunet from '@/components/Themes/FooterComponentEdunet.vue';
import FooterComponentEdunetke from '@/components/Themes/FooterComponentEdunetke.vue';
import FooterComponentEdunetasia from '@/components/Themes/FooterComponentEdunetasia.vue';
import FooterComponentEdunetin from '@/components/Themes/FooterComponentEdunetin.vue';
import FooterComponentEdunetma from '@/components/Themes/FooterComponentEdunetma.vue';
import FooterComponentT4a from '@/components/Themes/FooterComponentT4a.vue';
import FooterComponentNcph from '@/components/Themes/FooterComponentNcph.vue';
import FooterComponentWconat from '@/components/Themes/FooterComponentWconat.vue';
import InfoBanner from '@/components/Header/InfoBanner.vue';
import { AppModule } from '@/store/modules/app';
import { group } from '@/config/configuration';
import TermsModal from '@/components/Authorization/TermsModal.vue';

@Component({
  components: {
    InformationNavigation,
    HeaderInformationNavigationJig,
    HeaderInformationNavigationTalentpool,
    HeaderInformationNavigationJobtalent,
    HeaderInformationNavigationEdunet,
    HeaderInformationNavigationEdunetke,
    HeaderInformationNavigationEdunetasia,
    HeaderInformationNavigationEdunetin,
    HeaderInformationNavigationEdunetma,
    HeaderInformationNavigationT4a,
    HeaderInformationNavigationNcph,
    HeaderInformationNavigationWconat,
    HeaderInformationNavigationKh,
    HeaderInformationNavigationRhh,
    HeaderInformationNavigationHe,
    PagesNavigation,
    RightSidebar,
    FooterComponent,
    FooterComponentJig,
    FooterComponentTalentpool,
    FooterComponentJobtalent,
    FooterComponentKh,
    InfoBanner,
    FooterComponentEdunet,
    FooterComponentEdunetke,
    FooterComponentEdunetasia,
    FooterComponentEdunetin,
    FooterComponentEdunetma,
    FooterComponentT4a,
    FooterComponentNcph,
    FooterComponentWconat,
    TermsModal,
    FooterComponentRhh,
    FooterComponentHe,
  },
})
export default class DefaultTemplate extends Vue {
  get isIframe() {
    return !AppModule.isIframe;
  }

  get rightSidebarClass() {
    const routesListForClass = ['Workers', 'Jobs'];
    return routesListForClass.includes(this.$route.name!) ? 'right-sidebar-extra-styles' : '';
  }

  get sid() {
    return AppModule.sid;
  }

  get groupTemplate() {
    return group(this.sid);
  }
}
</script>

<style lang="scss" scoped>
.right-sidebar-extra-styles {
  margin-top: 52px;
}
</style>
