export enum ThemeSids {
  aba = 'aba',
  abatest = 'abatest',
  jig = 'jig',
  talentpool = 'talentpool',
  edunet = 'edunet',
  wconat = 'wconat',
  ncph = 'ncph',
  t4a = 't4a',
  edunetke = 'edunetke',
  kh = 'kh',
  rhh = 'rhh',
  edunetasia = 'edunetasia',
  edunetma = 'edunetma',
  he = 'he',
  jobtalent = 'jobtalent',
  edunetin = 'edunetin',
}

export interface ThemeSite {
  domain?: string
  sid: ThemeSids
  site?: string
  colors: {
    primary: string
    secondary: string
  }
  title?: any
  language?: string
  languageDropdown: boolean
}

export interface ThemeColors {
  'jc_main_title_color': string
  'jc_color': string
  'jc_head_color': string
  'jc_table_head_color': string
  'jc_arrows_color': string
  'jc_active_tab_color': string
  'jc_inactive_tab_color': string
  'jc_hover_tab_color': string
  'jc_body_bg_color': string
  'jc_th_bg_color': string
  'jc_td_bg_color': string
  'jc_info_banner_bg_color': string
  'jc_info_banner_font_color': string
  'jc_info_banner_link_color': string
}
