<template>
  <b-card no-body class="bg-white border mb-2">
    <b-card-body class="d-flex align-items-center p-2">
      <div class="professions-item mr-1">
        <div class="d-flex align-items-center">
          <span class="text-truncate" :class="[{'fw-bolder': top}]">
            <span class="text-aba-primary me-2" v-if="top">TOP {{ index }}</span>
            {{ title }}
          </span>
          <b-button
            v-if="inf.length && inf !== 'inf' && sid === 'jobtalent'"
            variant="light"
            class="d-flex mx-1"
            size="sm"
            v-b-tooltip.hover
            :title="translations[13839]"
            @click="handleInfo()"
          >
            <b-icon icon="journals" font-scale="0.98" />
          </b-button>
        </div>
      </div>
      <b-dropdown
        @click="searchProfession()"
        class="ml-auto"
        split
        split-variant="outline-primary"
        split-class="split-width"
        right
        :text="`${quantity}`"
        variant="primary" size="sm">
        <b-dropdown-item href="#" class="d-flex align-items-top text-nowrap"  @click.prevent="searchProfession()">
          <b-icon icon="list-ul" class="mr-2" />
          {{ translations[13500] }}
        </b-dropdown-item>
        <b-dropdown-item @click.prevent="searchProfessionMap()" href="#" class="d-flex align-items-top text-nowrap">
          <b-icon icon="geo-alt" class="mr-2" />
          {{ translations[13501] }}
        </b-dropdown-item>
        <b-dropdown-item
          :href="isAuth ? `https://job-server.net/jobcentral/pdf?sid=${languageSid}&f=file&type=pdf&id=b${id}` : ''"
          v-if="pdf"
          v-b-modal="!isAuth ? 'not-auth-modal' : ''" class="d-flex align-items-top text-nowrap">
          <b-icon icon="file-earmark-pdf" class="mr-2" />
          {{ translations[10426] }}
          <span v-if="!isAuth" class="ml-auto" v-b-tooltip.hover title="Bitte registrieren, um diese Funktion zu nutzen.">
            <b-icon icon="shield-lock-fill" />
          </span>
        </b-dropdown-item>
        <b-dropdown-item v-if="sid === 'jobtalent'" :href="onClickExcel" target="_blank" class="d-flex align-items-top text-nowrap">
          <b-icon icon="file-earmark-pdf" class="mr-2" />
          {{ translations[10449] }}
        </b-dropdown-item>
<!--        <b-dropdown-item :href="isAuth ? onClickExcel : ''" target="_blank" v-b-modal="!isAuth ? 'not-auth-modal' : ''" class="d-flex align-items-top text-nowrap">-->
<!--          <b-icon icon="file-earmark-pdf" class="mr-2" />-->
<!--          {{ translations[10449] }}-->
<!--          <span v-if="!isAuth" class="ml-auto" v-b-tooltip.hover title="Bitte registrieren, um diese Funktion zu nutzen.">-->
<!--            <b-icon icon="shield-lock-fill" />-->
<!--          </span>-->
<!--        </b-dropdown-item>-->
      </b-dropdown>
    </b-card-body>
  </b-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ProfessionModule } from '@/store/modules/profession';
import { JobType } from '@/types/ProfessionType';
import { AppModule } from '@/store/modules/app';
import { MapModule } from '@/store/modules/map';
import { handleError } from '@vue/runtime-dom';

@Component({
  methods: { handleError },
})
export default class ProfessionsItem extends Vue {
  @Prop({ default: false }) top!: boolean;

  @Prop({ default: 0 }) private index!: number;

  @Prop({ default: '' }) private title!: string;

  @Prop({ default: 0 }) private quantity!: number;

  @Prop({ default: 0 }) private plt!: number;

  @Prop({ default: 0 }) private pdf!: number;

  @Prop({ default: 0 }) private id!: number;

  @Prop({ default: '' }) public sid!: string;

  @Prop({ default: '' }) private inf!: string;

  @Prop({ default: false }) isAuth!: boolean;

  @Prop({ default: '' }) languageSid!: string;

  get translations() {
    return AppModule.translation;
  }

  get onClickExcel() {
    const objectParams = {
      id: this.id,
      sid: this.languageSid,
      jobnews_type: 1,
      xls: 1,
    } as any;
    const params = new URLSearchParams(objectParams).toString();
    return `https://api.job-server.net/v1/jobnews/job_list?${params}`;
  }

  searchProfession() {
    ProfessionModule.SetParamsDefault();
    ProfessionModule.SetProfessionId(this.id);
    ProfessionModule.SetProfessionTitle(this.title);
    ProfessionModule.SetJobType(JobType.items);
    AppModule.SetVisibleJobs(true);
  }

  searchProfessionMap() {
    ProfessionModule.SetParamsDefault();
    MapModule.SetProfessionMapId(this.id);
    ProfessionModule.SetProfessionTitle(this.title);
    ProfessionModule.SetJobType(JobType.map);
    AppModule.SetVisibleMap(true);
  }

  handleInfo() {
    ProfessionModule.SetInformation({ title: this.title, info: this.inf, id: this.id });
    this.$root.$emit('bv::toggle::collapse', 'group-sidebar');
  }
}
</script>
