import {
  VuexModule, Module, Mutation, Action, getModule,
} from 'vuex-module-decorators';
// import { setLanguage } from '@/plugins/cookies';
import store from '@/store';
import { getCountries } from '@/api/Language';
// eslint-disable-next-line import/named
import { getPortalJobCount, getPortalSetting } from '@/api/Setting';
import { getTranslationData, getTranslationTextData, settingsItems } from '@/api/Translation';
import { translationsIds } from '@/config/translations';
import { JobType } from '@/types/ProfessionType';
import { getCompanyClustersCount } from '@/api/Clusters';
import { getLanguage, setLanguage } from '@/plugins/cookies';
import { getByDomain } from '@/config/sites';

const ThemeDetection = getByDomain();

export enum AuthTab {
  applicant = 'applicant',
  firm = 'company',
  firmAlpha = 'companyAlpha',
  support = 'consultant',
  default = ''
}

type SettingsCluster = {
  id: number
  name: string
  picture: string
}

export type SettingsCounters = {
  name: 'companies' | 'applicants' | 'jobs' | 'maps'
  visible: boolean
}

export interface IAppState {
  language: string
  country: any
  translation: object
  languageSid: string
  sid: string
  setting: any
  settingPortal: {
    applicant_fields_disabled: any[]
    clusters: SettingsCluster[]
    header_image: any
    content_title: null | string
    content_description: null | string
    counters: SettingsCounters[]
    html_title: null | string
    portal_name: null | string
    search_input_jobs_placeholder: null | string
    header_image_text: any
    info_bars: any[]
    landing_bars: any[]
    occupational_cluster_text: any
    job_search_hint_disable: 0 | 1
    not_logged_in_block_job_ads: boolean
    not_logged_in_blur_employer_data: boolean
  }
  isRegistrationTab: boolean
  privacy: boolean
  visibleMap: boolean
  visibleJobs: boolean
  visibleClusterJobs: boolean
  visibleCompany: boolean
  jobCountPortal: { clusters: { id: number, count: number}[] }
  companyCountPortal: { clusters: { id: number, count: number}[] }
  selectedAuthTab: AuthTab
  typeJobList: number
}

interface TranslationsOnject {
  [key: string]: string
}

const createTranslationsObject = (initialObj: TranslationsOnject): TranslationsOnject => new Proxy(initialObj, {
  get(target, prop: string) {
    return prop in target ? target[prop] : '';
  },
  set(target, prop: string, value: string) {
    const newTarget = { ...target, [prop]: value };
    Object.assign(target, newTarget);
    return true;
  },
});

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements IAppState {
  // public language = getLanguage() || 'de';
  public language = ThemeDetection && ThemeDetection.language ? ThemeDetection.language : 'de';

  public rtl: 'ltr' | 'rtl' = 'ltr';

  public translation = createTranslationsObject({});

  public sid = 'jobtalent';

  public languageSid = '';

  public country = [] as any;

  public setting = {} as any;

  public settingPortal: IAppState['settingPortal'] = {
    applicant_fields_disabled: [],
    clusters: [] as SettingsCluster[],
    header_image: null,
    header_image_text: null,
    info_bars: [],
    occupational_cluster_text: null,
    html_title: null,
    content_title: null,
    content_description: null,
    counters: [],
    portal_name: null,
    search_input_jobs_placeholder: null,
    job_search_hint_disable: 0,
    landing_bars: [],
    not_logged_in_block_job_ads: false,
    not_logged_in_blur_employer_data: false,
  };

  // auth modal
  public isRegistrationTab = false;

  public selectedAuthTab = AuthTab.default;
  // end auth modal

  public jobCountPortal = {
    clusters: [],
  }

  public companyCountPortal = {
    clusters: [],
  }

  public privacy = false;

  visibleMap = false

  visibleJobs = false

  visibleClusterJobs = false

  visibleCompany = false

  isIframe = false;

  isWelcome= false;

  typeJobList = 0;

  get getLanguage(): string {
    return this.language;
  }

  // Mutation
  @Mutation
  SET_WELCOME(payload: boolean) {
    this.isWelcome = payload;
  }

  @Mutation
  private SET_PRIVACY(payload: boolean) {
    this.privacy = payload;
  }

  @Mutation
  private SET_IS_IFRAME(payload: boolean) {
    this.isIframe = payload;
  }

  // Mutation
  @Mutation
  private SET_PORTAL_JOB_COUNT(payload: any) {
    this.jobCountPortal = payload;
  }

  @Mutation
  private SET_PORTAL_COMPANY_COUNT(payload: any) {
    this.companyCountPortal = payload;
  }

  @Mutation
  private SET_COUNTRY(language: any) {
    this.country = language;
  }

  @Mutation
  SET_REGISTRATION_TAB(value: IAppState['isRegistrationTab']) {
    this.isRegistrationTab = value;
  }

  @Mutation
  SET_SELECTED_AUTH_TAB(value: IAppState['selectedAuthTab']) {
    this.selectedAuthTab = value;
  }

  @Mutation
  private SET_LANGUAGE(language: string) {
    this.language = language;
    this.rtl = language === 'fa' || language === 'ar' ? 'rtl' : 'ltr';
  }

  @Mutation
  private SET_TRANSLATION(data: any) {
    this.translation = createTranslationsObject(data);
  }

  @Mutation
  private SET_SID(request: any) {
    this.sid = request;
  }

  @Mutation
  private SET_LANGUAGE_SID(request: any) {
    this.languageSid = request;
  }

  @Mutation
  private SET_SETTING(request: any) {
    this.setting = request;
  }

  @Mutation
  private SET_SETTING_PORTAL(request: any) {
    this.settingPortal = request;
  }

  @Mutation
  private SET_VISIBLE_MAP(payload: any) {
    this.visibleMap = payload;
  }

  @Mutation
  private SET_VISIBLE_CLUSTER_JOBS(payload: any) {
    this.visibleClusterJobs = payload;
  }

  @Mutation
  private SET_VISIBLE_JOBS(payload: any) {
    this.visibleJobs = payload;
  }

  @Mutation
  private SET_VISIBLE_COMPANY(payload: any) {
    this.visibleCompany = payload;
  }

  @Action
  SetVisibleCompany(payload: boolean) {
    this.SET_VISIBLE_COMPANY(payload);
  }

  @Action
  SetVisibleMap(payload: boolean) {
    this.SET_VISIBLE_MAP(payload);
  }

  @Action
  SetVisibleJobs(payload: boolean) {
    this.SET_VISIBLE_JOBS(payload);
  }

  @Action
  SetVisibleClusterJobs(payload: boolean) {
    this.SET_VISIBLE_CLUSTER_JOBS(payload);
  }

  @Action
  public setPrivacy(payload: boolean) {
    this.SET_PRIVACY(payload);
  }

  @Action
  public ActionSid(payload: string) {
    this.SET_SID(payload);
  }

  @Action
  public ActionLanguageSid(sid: string) {
    this.SET_LANGUAGE_SID(sid);
  }

  @Action
  async getTranslation() {
    const strings = await getTranslationData({ lang: this.language, ids: translationsIds, sid: this.languageSid });
    this.SET_TRANSLATION(strings);
  }

  @Action({ rawError: true })
  async ActionCountry(params: any): Promise<void> {
    try {
      const request = await getCountries(params);
      this.SET_COUNTRY(request);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action
  public ActionLanguage(lang: string) {
    this.SET_LANGUAGE(lang);
    // setLanguage(lang);
    if (lang !== 'de') {
      this.SET_LANGUAGE_SID(`${this.sid}_${lang}`);
    } else {
      this.SET_LANGUAGE_SID(`${this.sid}`);
    }
  }

  @Action({ rawError: true })
  async getSetting(params: any): Promise<void> {
    try {
      const request = await settingsItems(params);
      this.SET_SETTING(request);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async getSettingPortal(params: any): Promise<void> {
    try {
      const request = await getPortalSetting(params);
      this.SET_SETTING_PORTAL(request);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  }

  @Action({ rawError: true })
  async getJobCountPortal(params: any): Promise<void> {
    try {
      const request = await getPortalJobCount(params);
      this.SET_PORTAL_JOB_COUNT(request);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  }

  @Action({ rawError: true })
  async GetCompanyCountPortal(payload: any) {
    const params = {
      ...payload,
    };
    const request = await getCompanyClustersCount(params);
    this.SET_PORTAL_COMPANY_COUNT(request);
  }

  @Action
  public SetIsIframe(payload: boolean) {
    this.SET_IS_IFRAME(payload);
  }

  @Action
  async getVisiblePage(value: SettingsCounters['name']) {
    return this.settingPortal.counters.find((val) => val.name === value)?.visible;
  }
}

export const AppModule = getModule(App);
