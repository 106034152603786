<template>
  <b-container fluid class="bg-aba-secondary" id="tabnav">
    <b-container class="py-2 pt-md-4 pb-md-0">
      <p class="text-white lh-1 fs-4 border-bottom border-dark-subtle pb-2 mb-2">
         <span v-if="sid === 'aba' || sid === 'abatest'">
          {{ portalName || translations[14519] }}
        </span>
        <span v-else>
          {{ portalName || '' }}
        </span>
      </p>
      <b-row no-gutters align-v="end">
        <b-col>
          <b-nav class="mb-0 d-none d-lg-flex">
            <b-nav-item
              class="nav-item"
              role="presentation"
              link-classes="pt-2 pb-2 text-white"
              :to="item.url"
              v-for="(item, index) in navigation"
              :key="index"
              :active="item.url === $route.path"
              @click="handleOpenTab($route.name, item.url === $route.path)"
              v-show="item.visible"
            >
              {{ item.text }}
            </b-nav-item>
            <b-nav-item
              v-b-toggle.auth-sidebar
              v-if="isAuth"
              class="nav-item ml-auto"
              role="presentation"
              link-classes="pt-2 pb-2 px-0 text-white"
              href="#"
            >
              <b-icon icon="person-circle" scale="0.8"></b-icon>
              <span class="d-none d-md-inline-block ml-2" v-if="accessTokenApplicant && userInfoApplicant && userInfoApplicant.applicant.firstname">
                {{ `${userInfoApplicant.applicant.firstname} ${userInfoApplicant.applicant.lastname ? userInfoApplicant.applicant.lastname : ''}` }}
              </span>
              <span class="d-none d-md-inline-block ml-2" v-else-if="accessTokenConsultant && userInfoConsultant">
                {{
                  `${userInfoConsultant.consultant.firstname} ${userInfoConsultant.consultant.lastname ? userInfoConsultant.consultant.lastname : ''}`
                }}
              </span>
              <span class="d-none d-md-inline-block ml-2" v-else-if="accessTokenCompany && userInfoCompany">
                {{ userInfoCompany.company?.company_name || '' }}
              </span>
            </b-nav-item>
            <b-nav-item
              v-if="isAuth"
              class="nav-item"
              role="presentation"
              v-b-toggle.notebook-sidebar
              link-classes="pt-2 pb-2 text-white d-inline-block"
              id="journal-tooltip"
              href="#"
            >
              <span class="position-relative d-inline-block">
                <b-icon icon="journal-bookmark-fill" class="d-block" scale="1.1" font-scale="0.98" />
                <span
                  style="top: -10px; right: -10px"
                  class="position-absolute start-100 translate-middle bg-danger rounded-circle p-1 mt-1 fs-7 lh-1"
                >
                  {{ notebookLength }}
                </span>
              </span>
              <b-tooltip target="journal-tooltip" left custom-class="custom-tooltip">{{ translations[13795] }}</b-tooltip>
            </b-nav-item>
            <b-nav-item
              v-if="!isAuth"
              class="nav-item ml-auto"
              role="presentation"
              link-classes="pt-2 pb-2 text-white"
              href="#"
              @click.prevent="authEvent"
              v-b-modal.login-modal
            >
              <b-icon icon="power" scale="0.8"></b-icon>
              <span class="d-none d-md-inline-block ml-2">{{ translations[13716] }}</span>
            </b-nav-item>
          </b-nav>
          <!--      Mobile navigation-->
          <b-row align-v="center" class="d-lg-none">
            <b-col cols="auto">
              <b-dropdown variant="primary" size="sm" text="" right class="border-0 w-100 text-start">
                <template v-slot:button-content>
                  <b-icon icon="list" class="mr-2"></b-icon>
                  <span class="selected">{{ activeNavigation }}</span>
                </template>
                <b-dropdown-item v-for="(item, index) in navigation" :key="`mobile_${index}`" exact exact-active-class="active" :to="item.url" v-show="item.visible">
                  {{ item.text }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <b-button @click.prevent="authEvent" v-if="!isAuth" variant="primary" size="sm" v-b-modal.login-modal>
                <b-icon icon="power"></b-icon>
              </b-button>
              <b-button v-else variant="primary" size="sm" v-b-toggle.auth-sidebar>
                <b-icon icon="person-circle"></b-icon>
              </b-button>
            </b-col>
          </b-row>
          <!--      Mobile navigation-->
        </b-col>
        <b-col cols="auto" class="ml-auto">
          <LanguageHeaderThemes />
        </b-col>
      </b-row>
    </b-container>
    <LoginModal />
    <RegisterModal />
    <ResetPasswordModal modal-id="applicant-reset-password" user-type="applicant" />
    <ResetPasswordModal modal-id="company-reset-password" user-type="company" />
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AppModule, AuthTab, SettingsCounters } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import LoginModal from '@/components/Authorization/LoginModal.vue';
import RegisterModal from '@/components/Authorization/RegisterModal.vue';
import ResetPasswordModal from '@/components/Authorization/ResetPasswordModal.vue';
import LanguageHeaderThemes from '@/components/Header/LanguageHeaderThemes.vue';
import { NotebookModule } from '@/store/modules/notebook';
import { WorkersModule } from '@/store/modules/workers';
import { ProfessionModule } from '@/store/modules/profession';
import { ClustersModule } from '@/store/modules/clusters';
import { CompanyModule } from '@/store/modules/company';
import { group } from '@/config/configuration';

@Component({
  components: {
    LoginModal,
    RegisterModal,
    LanguageHeaderThemes,
    ResetPasswordModal,
  },
})
export default class PagesNavigation extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get portalName() {
    return AppModule.settingPortal.portal_name;
  }

  get isAuth() {
    return UserModule.isUserAuth;
  }

  // TokenApplicant
  get accessTokenApplicant() {
    return UserModule.accessTokenApplicant;
  }

  // TokenConsultant
  get accessTokenConsultant() {
    return UserModule.accessTokenConsultant;
  }

  // TokenCompany
  get accessTokenCompany() {
    return UserModule.accessTokenCompany;
  }

  get userInfoApplicant() {
    return UserModule.userInfoApplicant;
  }

  get userInfoConsultant() {
    return UserModule.userInfoConsultant;
  }

  get userInfoCompany() {
    return UserModule.userInfoCompany;
  }

  get notebookLength() {
    return Number(NotebookModule.notebookJobs.length) + Number(NotebookModule.notebookWorkers.length) + Number(NotebookModule.notebookCompany.length);
  }

  get sid() {
    return AppModule.sid;
  }

  get isHide() {
    return !group(this.sid);
  }

  get getCounters() {
    return AppModule.settingPortal.counters;
  }

  isShowPage(value: SettingsCounters['name']) {
    return this.getCounters.find((val) => val.name === value)?.visible;
  }

  get navigation() {
    return [
      {
        url: '/',
        text: this.translations[13801],
        visible: true,
      },
      {
        url: '/jobs',
        text: this.sid === 'jobtalent' ? this.translations[15398] : this.translations[12604],
        visible: this.isShowPage('jobs'),
      },
      {
        url: '/company',
        text: this.translations[13937],
        visible: this.isShowPage('companies'),
      },
      {
        url: '/workers',
        text: this.sid === 'jobtalent' ? this.translations[15399] : this.translations[4741],
        visible: this.isShowPage('applicants'),
      },
      {
        url: '/map',
        text: this.translations[13719],
        visible: this.isHide,
      },
    ];
  }

  get activeNavigation() {
    return this.navigation.find((x) => this.$route.path === x.url)?.text;
  }

  authEvent() {
    UserModule.IS_REDIRECT_TO_CABINET(true);
  }

  handleOpenTab(currentRoute: string, isActive: boolean) {
    // reset every tab here;
    if (isActive) {
      switch (currentRoute) {
        case 'Jobs':
          AppModule.SetVisibleJobs(false);
          AppModule.SetVisibleMap(false);
          AppModule.SetVisibleClusterJobs(false);
          ProfessionModule.SetParamsDefault();
          ProfessionModule.SetTagsDefault();
          ClustersModule.ResetClusterData();
          break;
        case 'Workers':
          WorkersModule.resetAllWorkersFilters();
          break;
        case 'Company':
          CompanyModule.SetParamsDefaultCompany();
          CompanyModule.SET_SELECTED_COMPANIES([]);
          AppModule.SetVisibleCompany(false);
          break;
        default:
          break;
      }
    }
  }

  handleAuthQuery() {
    const query = this.$route.query.auth;
    let selectedAuthTab = AuthTab.applicant;
    let setRegistrationTab = false;

    switch (query) {
      case 'a-login':
        break;
      case 'f-login':
        selectedAuthTab = AuthTab.firm;
        break;
      case 'a-registration':
        setRegistrationTab = true;
        break;
      case 'f-registration':
        selectedAuthTab = AuthTab.firm;
        setRegistrationTab = true;
        break;
      default:
        return;
    }

    AppModule.SET_SELECTED_AUTH_TAB(selectedAuthTab);

    if (setRegistrationTab) {
      this.$bvModal.show('register-modal');
      return;
    }

    this.$bvModal.show('login-modal');
  }

  // TODO: maybe move to app? but no need with iframe
  mounted() {
    if (!this.accessTokenApplicant && !this.accessTokenCompany && !this.accessTokenConsultant) {
      this.handleAuthQuery();
    }
  }
}
</script>
