export const translationsIds = [
  2016, 227, 228, 229, 5357, 5355, 5356, 4671, 11428, 10469, 1283, 232, 10870, 238, 2521, 236, 11846, 11845, 10540, 10541, 10542, 10543, 11839, 10808,
  10809, 10810, 723, 722, 1005, 11849, 11850, 876, 11966, 878, 10886, 5456, 12601, 12688, 2698, 2894, 2114, 11496, 12829, 12815, 13586, 13587, 13721,
  13588, 13589, 1332, 13590, 13597, 13598, 13599, 13600, 13422, 13601, 13602, 13603, 13604, 13605, 13606, 13607, 13237, 13608, 13609, 13610, 13610,
  13611, 13612, 13613, 13614, 13615, 13616, 2013, 609, 13617, 10436, 10437, 13618, 13619, 13620, 13621, 13622, 12150, 13623, 13624, 13625, 13626,
  13627, 13628, 13629, 13630, 13631, 13632, 13633, 13634, 13716, 12604, 13717, 4741, 341, 13719, 13724, 9502, 10430, 10432, 9503, 13726, 1388, 13727,
  13728, 13729, 13730, 13731, 14016, 13733, 13734, 13735, 13732, 12917, 13736, 13635, 13636, 13737, 13269, 13270, 13738, 13645, 13646, 13647, 13648,
  11251, 11119, 13720, 10801, 10802, 10803, 11152, 13739, 11187, 13712, 13713, 13740, 5821, 847, 691, 10682, 13741, 13742, 289, 291, 709, 708, 290,
  292, 293, 609, 13617, 10436, 10437, 13618, 13619, 13620, 13621, 13622, 12150, 13623, 13624, 13625, 13626, 276, 277, 278, 279, 280, 13746, 13747,
  13749, 13751, 13752, 13753, 13754, 2901, 13591, 13592, 13593, 13594, 1330, 1331, 10473, 13596, 13299, 13755, 13756, 13757, 404, 13759, 13760,
  10431, 6206, 13640, 13641, 10569, 13642, 13643, 13644, 13637, 1135, 3764, 13766, 13772, 13773, 13769, 13768, 13774, 13770, 13777, 13778, 13779,
  13780, 13771, 13782, 13783, 13785, 13786, 13784, 11972, 707, 9560, 11486, 11487, 327, 13790, 13639, 13792, 13791, 13789, 6226, 10966, 13794, 13793,
  13795, 13796, 2438, 13797, 10997, 13575, 13798, 13717, 1239, 2142, 11858, 11859, 4660, 2553, 1478, 1479, 3228, 3211, 1480, 1000, 3326, 1470, 1471,
  2173, 1488, 2142, 1485, 1475, 1473, 1816, 2482, 3351, 2471, 11383, 13799, 13800, 13801, 13817, 13818, 13439, 1612, 1613, 1611, 1617, 1475, 483, 480,
  481, 5404, 13842, 457, 246, 484, 10908, 13846, 9502, 9506, 2983, 13781, 13850, 10988, 13649, 13867, 13871, 9207, 13872, 13868, 13803, 10940, 13255,
  13869, 13870, 13521, 11097, 4755, 4756, 11483, 13890, 13893, 13898, 13899, 13932, 13933, 13937, 13936, 13934, 13935, 13938, 13939, 13886, 13940,
  13941, 13943, 13944, 13945, 13946, 13947, 13948, 10945, 13569, 13891, 13894, 4805, 13980, 1474, 1484, 5549, 10449, 13500, 13501, 10426, 728, 13995,
  13996, 13997, 13998, 13999, 14000, 14001, 14002, 14003, 14004, 3627, 14006, 11115, 14011, 14014, 14012, 13986, 2837, 14019, 14020, 10983, 519, 2571,
  14408, 14405, 11001, 10948, 1165, 14409, 14435, 14436, 14437, 14459, 14470, 14471, 14472, 14473, 14519, 14584, 14670, 14671, 14672, 14832, 13693,
  305, 1324, 2842, 2843, 2844, 2845, 2851, 1326, 2887, 11288, 10798, 11663, 505, 1983, 14462, 14765, 14787, 14786, 234, 235, 11441, 14474, 14694,
  14949, 3575, 14950, 398, 401, 402, 403, 404, 405, 406, 407, 408, 690, 999, 688, 858, 268, 3424, 313, 570, 397, 770, 3423, 13725, 14996, 2053,
  6229, 14997, 14998, 5661, 1208, 15008, 15035, 15036, 15037, 15038, 15039, 15040, 15041, 15042, 15043, 15044, 15045, 240, 15058, 15057, 2114, 11497,
  15202, 1315, 15195, 15196, 15197, 15199, 15203, 15204, 15205, 15206, 15207, 15208, 15218, 3407, 3406, 10815, 10814, 10813, 10812, 1724, 10823,
  15201, 1505, 15219, 15200, 15220, 15221, 15222, 9209, 546, 547, 564, 3843, 15260, 15261, 2524, 2527, 2528, 2529, 15084, 13839, 12834, 12833,
  12843, 12840, 12841, 12842, 12839, 12836, 12837, 12838, 13585, 12835, 12185, 11855, 11871, 4810, 12181, 12182, 12180, 11817, 10417, 10904, 12202,
  15398, 15399, 15391,
];
