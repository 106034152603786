// eslint-disable-next-line import/named
import { ThemeSite, ThemeSids } from '@/types/ThemesType';

export const domainDatabase: ThemeSite[] = [
  // localhost default ---------------------------------
  {
    domain: 'localhost',
    sid: ThemeSids.jobtalent,
    // site: 'https://jobtalent.space',
    colors: { primary: '#CCD300', secondary: '#CCD300' },
    // language: 'en',
    languageDropdown: true,
  },
  // localhost default ---------------------------------
  {
    domain: 'portal.workinaustria.com',
    sid: ThemeSids.aba,
    colors: { primary: '#d83818', secondary: '#d83818' },
    languageDropdown: true,
  },
  {
    domain: 'jobs.workinaustria.com',
    sid: ThemeSids.aba,
    colors: { primary: '#d83818', secondary: '#d83818' },
    languageDropdown: true,
  },
  {
    domain: 'work.workinaustria.com',
    sid: ThemeSids.abatest,
    colors: { primary: '#d83818', secondary: '#d83818' },
    languageDropdown: true,
  },
  {
    domain: 'jig.jobmatch.works',
    sid: ThemeSids.jig,
    colors: { primary: '#E30613', secondary: '#E30613' },
    languageDropdown: true,
    title: {
      de: 'Jobs in Germany - Talent Pool',
      en: 'Jobs in Germany - Talent Pool',
    },
  },
  {
    domain: 'jobs.jig.world',
    sid: ThemeSids.jig,
    colors: { primary: '#E30613', secondary: '#E30613' },
    languageDropdown: true,
    title: {
      de: 'Jobs in Germany - Talent Pool',
      en: 'Jobs in Germany - Talent Pool',
    },
  },
  {
    domain: 'talentpool.jobmatch.works',
    sid: ThemeSids.talentpool,
    colors: { primary: '#CCD300', secondary: '#CCD300' },
    languageDropdown: true,
    title: {
      de: 'JobMATCH Talent Pool',
      en: 'JobMATCH Talent Pool',
    },
  },
  {
    domain: 'jobtalent.space',
    sid: ThemeSids.jobtalent,
    colors: { primary: '#CCD300', secondary: '#CCD300' },
    languageDropdown: true,
    title: {
      de: 'JobMATCH Talent Pool',
      en: 'JobMATCH Talent Pool',
    },
  },
  {
    domain: 'talentpool.edunet.works',
    sid: ThemeSids.edunet,
    site: 'https://edunet.works',
    colors: { primary: '#FC9E16', secondary: '#FC9E16' },
    languageDropdown: true,
    title: {
      de: 'Edunet Talent Pool',
      en: 'Edunet Talent Pool',
    },
  },
  {
    domain: 'talentpool.edunet.africa',
    sid: ThemeSids.edunetke,
    site: 'https://edunet.africa',
    colors: { primary: '#BB0000', secondary: '#BB0000' },
    language: 'en',
    languageDropdown: true,
    title: {
      de: 'Edunet Talent Pool',
      en: 'Edunet Talent Pool',
    },
  },
  {
    domain: 'talentpool.edunet.ke',
    sid: ThemeSids.edunetke,
    site: 'https://edunet.ke',
    colors: { primary: '#BB0000', secondary: '#BB0000' },
    language: 'en',
    languageDropdown: true,
    title: {
      de: 'Edunet Talent Pool',
      en: 'Edunet Talent Pool',
    },
  },
  {
    domain: 'talentpool.workconsult.at',
    sid: ThemeSids.wconat,
    colors: { primary: '#bf1e2e', secondary: '#bf1e2e' },
    languageDropdown: true,
    title: {
      de: 'JobMATCH Talent Pool',
      en: 'JobMATCH Talent Pool',
    },
  },
  {
    domain: 'talentpool.nursingcareer.ph',
    sid: ThemeSids.ncph,
    colors: { primary: '#006c68', secondary: '#006c68' },
    language: 'en',
    languageDropdown: false,
    title: {
      de: 'JobMATCH Talent Pool',
      en: 'JobMATCH Talent Pool',
    },
  },
  {
    domain: 'work.talents4austria.com',
    sid: ThemeSids.t4a,
    colors: { primary: '#bf1e2e', secondary: '#bf1e2e' },
    languageDropdown: true,
    title: {
      de: 'JobMATCH Talent Pool',
      en: 'JobMATCH Talent Pool',
    },
  },
  {
    domain: 'kh.jobmatch.works',
    sid: ThemeSids.kh,
    colors: { primary: '#1196c2', secondary: '#1196c2' },
    languageDropdown: true,
    title: {
      de: 'JobMATCH Talent Pool',
      en: 'JobMATCH Talent Pool',
    },
  },
  {
    domain: 'rheinhessen.jobmatch.works',
    sid: ThemeSids.rhh,
    colors: { primary: '#85CE25', secondary: '#85CE25' },
    languageDropdown: true,
    title: {
      de: 'JobMATCH Talent Pool',
      en: 'JobMATCH Talent Pool',
    },
  },
  {
    domain: 'talentpool.edunet.asia',
    sid: ThemeSids.edunetasia,
    colors: { primary: '#ff6c2c', secondary: '#ff6c2c' },
    language: 'en',
    site: 'https://edunet.asia',
    languageDropdown: true,
    title: {
      de: 'Edunet Talent Pool',
      en: 'Edunet Talent Pool',
    },
  },
  {
    domain: 'talentpool.edunet.in',
    sid: ThemeSids.edunetin,
    colors: { primary: '#ff6c2c', secondary: '#ff6c2c' },
    language: 'en',
    site: 'https://edunet.asia',
    languageDropdown: true,
    title: {
      de: 'Edunet Talent Pool',
      en: 'Edunet Talent Pool',
    },
  },
  {
    domain: 'talentpool.edunet.co.ma',
    sid: ThemeSids.edunetma,
    colors: { primary: '#ca2a2a', secondary: '#ca2a2a' },
    language: 'fr',
    site: 'https://edunet.co.ma',
    languageDropdown: true,
    title: {
      de: 'Edunet Talent Pool',
      en: 'Edunet Talent Pool',
    },
  },
  {
    domain: 'work.talentpool-hessen.de',
    sid: ThemeSids.he,
    site: 'https://talentpool-hessen.de',
    colors: { primary: '#85CE25', secondary: '#85CE25' },
    languageDropdown: true,
    title: {
      de: 'JobMATCH Talent Pool',
      en: 'JobMATCH Talent Pool',
    },
  },
];

export const getByDomain = (): ThemeSite | null => {
  const url = window.location.href;
  const parsedUrl = new URL(url);
  const domain = parsedUrl.hostname;

  const strippedDomain = domain.startsWith('www.') ? domain.slice(4) : domain;
  const site = domainDatabase.find((site) => site.domain === strippedDomain);
  return site || null;
};
