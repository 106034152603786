<template>
  <div>
    <template v-if="languageDropdown">
      <b-dropdown
        v-if="isGroupSid"
        variant="link"
        size="sm"
        text=""
        class="text-start"
        toggle-class="text-decoration-none px-lg-3 py-lg-2 text-white language-dropdown" no-caret
        :dropleft="isRtl !== 'ltr'"
        right>
        <template v-slot:button-content>
          <span>{{ currentLang.code }}</span>
        </template>
        <b-dropdown-item
          v-for="(item, index) in languages"
          @click="setLanguage(item.code)"
          :key="`language_item_${index}`"
          v-show="item.code !== lang && item.visible">
          <span :class="`fi fis fi-${item.code} rounded-circle`"></span>
          <span class="ml-2">{{ item.title }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <b-button
      v-else
      variant="link"
      size="sm"
      class="text-decoration-none px-lg-3 py-lg-2 text-white language-dropdown language-dropdown-default">
      <span>{{ lang }}</span>
    </b-button>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { ThemesModule } from '@/store/modules/themes';
import { group } from '@/config/configuration';

@Component
export default class LanguageHeaderThemes extends Vue {
  private languages = [
    { code: 'de', title: 'Deutsch', visible: true },
    { code: 'en', title: 'English', visible: true },
    { code: 'tr', title: 'Türkçe', visible: this.isGroupSid1 || this.isGroupSid2 },
    { code: 'ar', title: 'العربية', visible: this.isGroupSid1 || this.isGroupSid2 || this.isGroupSid4 },
    { code: 'fr', title: 'Français', visible: this.isGroupSid1 || this.isGroupSid2 || this.isGroupSid4 },
    // { code: 'it', title: 'Italiano', visible: this.isTalentpoolSid },
    { code: 'es', title: 'Español', visible: this.isGroupSid1 || this.isGroupSid2 },
    // { code: 'pt', title: 'Portugal', visible: this.isTalentpoolSid },
    // { code: 'pl', title: 'Polska', visible: this.isTalentpoolSid },
    // { code: 'ro', title: 'Română', visible: this.isTalentpoolSid },
    { code: 'uk', title: 'Українська', visible: this.isGroupSid1 || this.isGroupSid2 || this.isGroupSid3 },
    { code: 'ru', title: 'Русский', visible: this.isGroupSid1 },
  ];

  get isRtl() {
    return AppModule.rtl;
  }

  get sid() {
    return AppModule.sid;
  }

  get isGroupSid1() {
    return ['jig'].includes(this.sid);
  }

  get isGroupSid2() {
    return ['talentpool', 'edunet', 'kh', 'rhh', 'he', 'jobtalent'].includes(this.sid);
  }

  get isGroupSid3() {
    return ['wconat', 't4a'].includes(this.sid);
  }

  get isGroupSid4() {
    return ['edunetma'].includes(this.sid);
  }

  get isGroupSid() {
    return group(this.sid);
  }

  get lang() {
    return AppModule.language;
  }

  get currentLang() {
    return this.languages.find((lang) => lang.code === this.lang);
  }

  setLanguage(code: string) {
    AppModule.ActionLanguage(code);
    this.$router.push({
      path: this.$route.fullPath,
      query: {
        lang: code,
      },
    });
    document.location.reload();
  }

  get languageDropdown() {
    return ThemesModule.sample.languageDropdown;
  }
}
</script>
<style lang="scss" scoped>

::v-deep(.language-dropdown) {
  text-transform: uppercase;
  box-shadow: none!important;
  background-color: transparent!important;
  font-weight: 700;
  .p-dropdown-trigger {
    display: none;
  }
  .p-dropdown-label {
    background: #ffffff;
  }
  &.rtl-dropdown {
    .dropdown-menu {
      left: 0!important;
    }
  }
}
::v-deep(.language-dropdown-default) {
  border-bottom: 5px solid transparent!important;
}

::v-deep(.rtl-dropdown) {
  left: 0!important;
  .dropdown-menu {
    left: 0!important;
  }
}
</style>
